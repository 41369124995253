<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('微信用户管理')}}</span>
        <div>
          <!-- <el-button type="primary" @click='dialogForm' v-if="user_role['customer_contacts/add']" size="small">新增联系人</el-button> -->
          <!-- <el-button type="primary" size="small" @click="getUserList(Page.page,1)">导出EXCEL</el-button> -->
        </div>
      </div>
      <div class="search">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-select v-model="status" :placeholder="$t('请选择')">
                <el-option :label="$t('正常')" :value="1"></el-option>
                <el-option :label="$t('黑名单')" :value="0"></el-option>
              </el-select>
          </el-col>
          <el-col :span="6">
            <el-select v-model="is_bind_contacts" clearable :placeholder="$t('联系人状态')">
                <el-option
                v-for="item in bindTypes"
                :key="item.id"
                :label="item.lablel"
                :value="item.id"></el-option>
              </el-select>
          </el-col>
          <el-col :span="6">
            <el-select v-model="is_bind_admin" clearable :placeholder="$t('管理员状态')">
                <el-option
                v-for="item in bindTypes"
                :key="item.id"
                :label="item.lablel"
                :value="item.id"></el-option>
              </el-select>
          </el-col>
          <el-col :span="6">
            <el-select v-model="oc_id" clearable :placeholder="$t('请选择客户ID')">
              <el-option v-for="item in customer" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
          </el-col>
        </el-row>

      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border>
        <el-table-column :label="$t('微信ID')" prop="openid" fixed="left" width="200" align="center" header-align="left">
        </el-table-column>
        <el-table-column :label="$t('微信头像')" width="200">
          <template slot-scope="scope">
            <img :src="scope.row.avatar" style="width: 50px; height: 50px;display: block;">
          </template>
        </el-table-column>
        <el-table-column :label="$t('微信名称')" prop="nickname" width="200">
        </el-table-column>
        <el-table-column :label="$t('归属客户')" prop="customername" width="200">
        </el-table-column>
        <el-table-column :label="$t('预约名称')" prop="contacts" width="200">
        </el-table-column>
        <el-table-column :label="$t('管理员名称')" prop="adminname" width="200">
        </el-table-column>
        <el-table-column :label="$t('状态')" width="200">
          <template slot-scope="scope">
            <span>{{scope.row.status==1?$t('正常'):$t('黑名单')}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="绑定时间" prop="bind_time" width="200">
        </el-table-column> -->
        <el-table-column :label="$t('注册时间')" prop="created_at" width="200">
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
            <el-button type="text" @click="setStatus(scope.row,0)" v-if="user_role['wxuser/setStatus']&&scope.row.status==1" size="small">{{$t('拉黑用户')}}</el-button>
            <el-button type="text" @click="setStatus(scope.row,1)" v-if="user_role['wxuser/setStatus']&&scope.row.status==0" size="small">{{$t('恢复用户')}}</el-button>
            <el-button type="text" @click="Unbinding(scope.row)" v-if="user_role['wxuser/relieveBind']" size="small">{{$t('解绑预约账户')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'weChart',
  data () {
    return {
      addAdmin: false,
      edit: false,
      UntreatedData: [],
      status: 1,
      oc_id: '',
      is_bind_contacts: '',
      is_bind_admin: '',
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      isModify: false,
      Detail: false,
      customer: [],
      user_role: {},
      bindTypes: [
        {
          id: 0,
          lablel: this.$t('未绑定')
        },
        {
          id: 1,
          lablel: this.$t('绑定')
        }
      ]
    }
  },
  created () {
    this.getUserList()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
  },
  beforeUpdate () {
    this.customer = this.$store.state.ocList
  },
  methods: {
    // 获取客户联系人列表
    getUserList (page) {
      let params = {
        page: page?page:this.Page.page,
        status: this.status,
        is_bind_contacts: this.is_bind_contacts,
        is_bind_admin: this.is_bind_admin,
        oc_id: this.oc_id
      }
      this.$axios.post('wxuser/lists',params).then((res) => {
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        } else this.$message.warning(Data.message)
      }).catch((error) => console.log(error))
    },
    // 修改用户状态
    setStatus (item, state) {
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将修改此用户状态, 是否继续?'),that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('wxuser/setStatus', {
          id: item.id,
          openid: item.openid,
          status: state
        }).then((res) => {
          const Data = res.data
          // console.log(Data)
          if (Data.code === 0) {
            this.$message.success(Data.message)
            this.getUserList()
          } else this.$message.warning(Data.message)
        }).catch((error) => console.log(error))
      })
    },
    // 解绑预约用户
    Unbinding (item) {
      this.$axios.post('wxuser/relieveBind', {
        id: item.id,
        openid: item.openid
      }).then((res) => {
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.$message.success(Data.message)
          this.getUserList()
        } else this.$message.warning(Data.message)
      }).catch((error) => console.log(error))
    },
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search{
    /* margin:15px 0; */
  }
  .search .el-col{
    margin: 10px 0;
  }
  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select {
    width: 100% !important;
  }

  .el-input.is-disabled::v-deep .el-input__inner {
    color: #575757;
  }
</style>
